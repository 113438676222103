import { useEffect, useState } from 'react';
import './App.css';
import sanityClient from './sanityClient';
import Header from "./components/Header";

function App() {

  const [riders, setRiders] = useState([]);

  useEffect(() => {
    sanityClient.fetch(`
      *[_type == "rider"] {
        ..., image{
          ...,
          asset->{url},
        }
      }`
    ).then((data) => setRiders(data)).catch((error) => console.log(error));
  }, [])


  return (
    <div className="max-w-4xl lg:max-w-5xl mx-auto">
      <Header />
      <div className="w-72 mx-auto m-12 lg:m-18 lg:mx-auto">
        <img src="logo.png" alt="logo"/>
      </div>
      <div>
        <h2 className="text-center text-2xl font-semibold">
          Ryttere
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {riders.sort(_ => Math.random() > 0.5 ? 1 : -1).map(rider => 
            <div className="m-8" key={rider._id}>
              {rider.image ? <img src={rider.image.asset.url} alt={rider.name} className="w-full h-72 object-cover rounded-lg drop-shadow-lg" loading="lazy" /> : <div className="w-full h-72 object-cover rounded-lg drop-shadow-lg bg-slate-200"></div> }
              <div className="font-semibold text-xl py-4">
                {rider.name}
              </div>
              <p className="text-slate-400 text-sm leading-6 pb-4">
                {rider.bio}
              </p>
            </div> 
          )}

          {riders.length ?
            <div className="m-8">
              <div className="w-full h-72 object-cover rounded-lg drop-shadow-lg bg-slate-200"></div>
              <div className="font-semibold text-xl py-4">
                Deg?
              </div>
              <p className="text-slate-400 text-sm leading-6 pb-4">
                Vi ser alltid er nye medlemmer som er nysgjerrige og engasjerte. Har du blandeveisykkel og er gira på tur?
              </p>
            </div> 
          : null}


        </div>
        <div className="h-32"></div>
      </div>
    </div>
  );
}

export default App;
